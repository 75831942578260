module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.7.0_gatsby-plugin-sharp@5.8.1_gatsby@5.9.1/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.7.0_gatsby@5.9.1_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Disenchanted","short_name":"Disenchanted","start_url":"/","background_color":"#ffffff","theme_color":"#5E81AC","display":"minimal-ui","icon":"content/assets/icon.png","icons":[{"src":"content/assets/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"content/assets/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"146d3b1b764a6a35d0bbadc4229b7483"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-typography@5.7.0_gatsby@5.9.1_react-dom@18.2.0_react-typography@0.16.23_react@18.2.0_typography@0.16.21/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@6.7.0_gatsby@5.9.1_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.9.1_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.1.6/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
